import { environment } from "src/environments/environment";

let onboardingBaseUrl =environment.onboardingBaseUrl;
let userRegistrationBaseUrl = environment.userRegistrationBaseUrl;
let otpServiceUrl = environment.otpServiceUrl;
let dedup_validUserChk = environment.dedup_validUserChk;
let servicesBaseUrl = environment.servicesBaseUrl;
let preOnboardingBaseUrl=environment.pre_onboardingBaseUrl;

export class allApi {
    public static url = {
      validatePAN: onboardingBaseUrl + '/KM/validate/pan',
      onboard_api: onboardingBaseUrl + `/kotak_cbc_onboarding/cbc_onboarding`,
      onboard_api_update: onboardingBaseUrl + `/kotak_cbc_onboarding_report/update_cbc_data`,
      viewlist_api: onboardingBaseUrl + `/kotak-common-onboarding-report/fetch_user_list`, //not done yet
      userdetails_api: onboardingBaseUrl + `/kotak-common-onboarding-report/details_from_username`,  //not done yet
      productList: environment.display_pob,
      bulk_registration: onboardingBaseUrl + "/kotak_bulkreg/bulk_registration",
      bulkUserCreation: onboardingBaseUrl + "/kotak_bulkreg/bulkUserCreation",
      email_mobile_otp: otpServiceUrl + '/user/send-mobile-email-verification-otp',
      email_mobile_otp_verify: otpServiceUrl + '/user/verify-email-mobile-verification-otp',
      bankPropertyDetails: userRegistrationBaseUrl + "/user/bank-property",
      user_registration: userRegistrationBaseUrl + "/user/registration",
      updatePassword: userRegistrationBaseUrl + "/user/send-change-password-otp",
      changePassword: userRegistrationBaseUrl + "/user/update-password-using-old-password",
      request_agent_feature_by_cbc_old: servicesBaseUrl + '/update_agent_feature_by_cbc',
      request_agent_feature_by_cbc_new: servicesBaseUrl + '/request_agent_feature_by_cbc',
      dashboard_cbc:environment.summaryDashboard,
      fetch_email:preOnboardingBaseUrl+'/fetch-mobile-email',
      update_email:preOnboardingBaseUrl+'/update-mobile-email',
      fetchProductListStatus: servicesBaseUrl + '/fetch_CBC_feature_status',
      assignProductFeature: servicesBaseUrl + '/request_CBC_feature',
      fetchFeatureListAgent: servicesBaseUrl + '/fetch_agent_list',
      fetch_agent_feature_status: servicesBaseUrl + '/fetch_agent_feature_status',
      dedup_check: dedup_validUserChk + '/dedup/check',
      validateUserName: dedup_validUserChk + '/check/user_name',
      getCityState: environment.getCityState,
      panVerifyConfirm:environment.panVerifyUrl
    };
}







