<ng-container *ngIf="navigationList">
  <ng-container *ngFor="let breadcrumb of navigationList; let last = last">
    <div class="page-header">
      <div class="page-block">
        <div class="row align-items-center">
          <div class="col-md-12">
            <div class="page-header-title">
              <h5 class="breadcrumb_font_size">{{title}}</h5>
            </div>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a [routerLink]="['/distributor/dashboard/']"><em class="fas fa-home"></em></a>
              </li>
              <ng-container *ngFor="let breadcrumb of navigationList">
                <li class="breadcrumb-item">{{breadcrumb.title}}</li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
