import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(private _http: HttpClient) {}

  get_method = (url: any) => {
    return this._http.get(url);
  };
  post_method = (url: any, reqBody: any) => {
    return this._http.post(url, reqBody);
  };

  masking_method(event: any, mode: any) {
    switch (mode) {
      case 'email':
        let emailParameter = event.substring(event.indexOf('@'));
        let emailPrefix = event.substring(0, event.indexOf('@'));
//a*******h @gmail.com
        if (emailPrefix.length > 2) {
          const maskedPart = 'x'.repeat(emailPrefix.length - 2);
          const result = `${emailPrefix.charAt(
            0
          )}${maskedPart}${emailPrefix.slice(-1)}${emailParameter}`;
          //console.log(result);
          return result;
        }
        break;
      case 'mobile':
        if (typeof event == 'number') {
          const maskedPart = 'X'.repeat(event.toString().length - 4);
          let result = maskedPart + event.toString().slice(-4);
          return result;
        } else {
          const maskedPart = 'X'.repeat(event.length - 4);
          let result = maskedPart + event.slice(-4);
          return result;
        }
        case 'address':
          const maskedPart = 'X'.repeat(event.length);
          let result = maskedPart + event.slice(event.length);
          return result;
          case 'dob':
            return  `XX-XX-${event.split('-')[2]}`;
        default:
          return event;

    }
  }
}
