
 ///////////// staging //////////////
 const StagingEnvironment = {
  production: false,
  superSetUrl: "https://superset.iserveu.tech",
  supersetId: "7f67c53c-1d7b-4793-b872-17af790c21e7",
  aepsDashSupersetId:"3b0be03a-bf1e-4b2f-87d0-1662384fc41a",
  onboardingDashSupersetId:"4021ccd1-2220-4d8e-b1e9-25352df84f5f",
  twowheelerDashSupersetId:"3814161e-a5c8-4cd2-b92e-b9ed16481d7e",
  supersetReqBody: {
    "password": "Test@2023",
    "provider": "db",
    "refresh": true,
    "username": JSON.parse(sessionStorage.getItem('dashboardData')).userName,
  },
  onboardingBaseUrl: 'https://apidev.iserveu.online/kotak',
  userRegistrationBaseUrl: 'https://user-registration-kotak-bank-uat-twdwtabx5q-el.a.run.app',
  otpServiceUrl: 'https://user-registration-kotak-bank-uat-twdwtabx5q-el.a.run.app',
  getCityState: 'https://pincode-fetch-staging-twdwtabx5q-uc.a.run.app/api/v1/getCitystateAndroid',
  dedup_validUserChk: 'https://elasticuser-staging-kotak-twdwtabx5q-uc.a.run.app',
  servicesBaseUrl: 'https://apidev.iserveu.online/kotak/kotak_feature_request',
  twoWheelerStatusApi: 'https://apidev.iserveu.online/stagingauth/kotakTwoWheeler',
  twoWheelerBaseUrl: 'https://two-wheelerstaging-zwqcqy3qmq-uc.a.run.app',
  commercialVeihicleReport: 'https://apidev.iserveu.online/CVCE/cbc/report',
  commercialVeihicleStatusCheck: 'https://apidev.iserveu.online/kotak/kotakcommercial/checkStatus',
  transactionReport: 'https://apidev.iserveu.online/kotak-txn-report/cashout-txn-report',
  pre_onboardingBaseUrl:'https://apidev.iserveu.online/user/kotak-common',
  authTokenApi:'https://oauth2-auth-server-kotak-bank-prod.iserveu.tech',
  display_pob:'https://apidev.iserveu.online/kotak/kotak/display_pob',
  summaryDashboard:'https://apidev.iserveu.online/kotak/dashboard_cbc/summary',
  panVerifyUrl:'https://apidev.iserveu.online/kotak/panverify/panvalidation',
  elasticSearch:'https://b61295e3bdc84097ac04e0456792cac1.us-central1.gcp.cloud.es.io:9243/kotak_elastic/_search'
};

 const UatEnvironment = {
  production: false,
  superSetUrl: "https://superset.iserveu.tech",
  supersetId: "7f67c53c-1d7b-4793-b872-17af790c21e7",
  aepsDashSupersetId:"3b0be03a-bf1e-4b2f-87d0-1662384fc41a",
  onboardingDashSupersetId:"4021ccd1-2220-4d8e-b1e9-25352df84f5f",
  twowheelerDashSupersetId:"3814161e-a5c8-4cd2-b92e-b9ed16481d7e",
  supersetReqBody: {
    "password": "Test@2023",
    "provider": "db",
    "refresh": true,
    "username": JSON.parse(sessionStorage.getItem('dashboardData')).userName,
  },
  summaryDashboard:'https://aeps-cbc-customer-report-zwqcqy3qmq-uc.a.run.app/summary_kotak_cbc',
  display_pob:'https://apidev.iserveu.online/ktuat/kotak/kotak/display_pob',
  onboardingBaseUrl: 'https://apidev.iserveu.online/ktuat/kotak',
  userRegistrationBaseUrl: 'https://isu-kotak-user-management-java-stage-uat-twdwtabx5q-el.a.run.app',
  otpServiceUrl: 'https://isu-kotak-user-management-java-stage-uat-twdwtabx5q-el.a.run.app',
  getCityState: 'https://pincode-fetch-staging-twdwtabx5q-uc.a.run.app/api/v1/getCitystateAndroid',
  dedup_validUserChk: 'https://eelasticuser-staging-kotak-stage-uat-twdwtabx5q-uc.a.run.app',
  servicesBaseUrl: 'https://apidev.iserveu.online/ktuat/kotak/kotak_feature_request',
  twoWheelerStatusApi: 'https://apidev.iserveu.online/ktuat/stagingauth/kotakTwoWheeler',
  twoWheelerBaseUrl: 'https://two-wheelerstaging-zwqcqy3qmq-uc.a.run.app', //.....
  commercialVeihicleReport: 'https://apidev.iserveu.online/ktuat/CVCE/cbc/report',
  commercialVeihicleStatusCheck: 'https://apidev.iserveu.online/ktuat/kotak/kotakcommercial/checkStatus',
  transactionReport: 'https://apidev.iserveu.online/ktuat/kotak-txn-report/cashout-txn-report',
  pre_onboardingBaseUrl:'https://apidev.iserveu.online/ktuat/user/kotak-common',
  panVerifyUrl:'https://apidev.iserveu.online/ktuat/kotak/panverify/panvalidation',
  authTokenApi:'https://oauth2-auth-server-kotak-bank-uat-twdwtabx5q-el.a.run.app',
  elasticSearch:'https://b61295e3bdc84097ac04e0456792cac1.us-central1.gcp.cloud.es.io:9243/kotak_elastic_uat/_search'
};

const ProdEnvironment = {
  production: true,
  superSetUrl: "https://superset.txninfra.com",
  supersetId: "7f67c53c-1d7b-4793-b872-17af790c21e7",
  aepsDashSupersetId:"8da7bafb-2a79-4205-b66b-da9bf623a449",
  onboardingDashSupersetId:"6aee46ff-09f2-445f-8eab-bbe5531ea6e6",
  twowheelerDashSupersetId:"c3012acb-f090-4ca1-817e-d4bbb08653f6",
  supersetReqBody: {
    "password": "Test@2023",
    "provider": "db",
    "refresh": true,
    "username": JSON.parse(sessionStorage.getItem('dashboardData')).userName,
  },
  summaryDashboard:'https://apiprodgateway.txninfra.com/kotak/dashboard_cbc/summary',
  display_pob: 'https://apiprod.txninfra.com/kotak/kotak-feature/display_pob',
  onboardingBaseUrl: 'https://apiprod.txninfra.com/kotak',
  userRegistrationBaseUrl: 'https://user-registration-kotak-bank-prod.txninfra.com',
  otpServiceUrl: 'https://apiprod.txninfra.com/kotak',
  getCityState: 'https://pincode-fetch-staging-twdwtabx5q-uc.a.run.app/api/v1/getCitystateAndroid',
  dedup_validUserChk: 'https://kotak-elastic-prod.txninfra.com',
  servicesBaseUrl: 'https://apiprod.txninfra.com/kotak/kotak_feature_request',
  twoWheelerStatusApi: 'https://apiprod.txninfra.com/kotak/kotak/kotakTwoWheeler',
  twoWheelerBaseUrl: 'https://apiprodgateway.txninfra.com/kotak',
  commercialVeihicleReport:'',
  commercialVeihicleStatusCheck:'',
  transactionReport: 'https://apiprodgateway.txninfra.com/kotak-txn-report/cashout-txn-report',
  pre_onboardingBaseUrl:'https://apiprodgateway.txninfra.com/kotak/common-user',
  panVerifyUrl:'https://apiprodgateway.txninfra.com/kotak/KM1/validate/pan',
  authTokenApi:'https://oauth2-auth-server-kotak-bank-prod.txninfra.com/oauth/token',
  elasticSearch:'https://b61295e3bdc84097ac04e0456792cac1.us-central1.gcp.cloud.es.io:9243/kotak_elastic_user/_search'
};


export const environment = UatEnvironment;
