import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NavigationItem } from 'src/app/navigation';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() type: string;

  public navigation: any;
  breadcrumbList: Array<any> = [];
  public navigationList: Array<any> = [];
  currentUrl = '';
  title: string;

  constructor(
    private route: Router,
    private titleService: Title,
    private Navigationitem: NavigationItem,
  ) {
    this.navigation = this.Navigationitem.navitems;
    this.type = 'theme2';
    this.setBreadcrumb();
  }

  ngOnInit() {
    let routerUrl: string;
    routerUrl = this.route.url;
    if (routerUrl && typeof routerUrl === 'string') {
      this.filterNavigation(routerUrl);
    }


  }

  setBreadcrumb() {
    let routerUrl: string;
    this.route.events.subscribe((router: any) => {
      routerUrl = router.urlAfterRedirects;
      if (routerUrl && typeof routerUrl === 'string') {
        this.breadcrumbList.length = 0;
        const activeLink = this.currentUrl = router.url;
        this.filterNavigation(activeLink);
      }
    });
  }

  filterNavigation(activeLink) {
    let result = [];
    let title = 'Welcome';
    this.navigation.forEach((a) => {
      if (a.parent === false && 'route' in a && '/' + a.route === activeLink) {
        result = [
          {
            url: ('route' in a) ? a.route : false,
            title: a.displayName,
            breadcrumbs: ('breadcrumbs' in a) ? a.breadcrumbs : true,
            type: 'item'
          }
        ];
        title = a.displayName;
      } else {
        if (a.parent === true && 'children' in a) {
          a.children.forEach((b) => {
            if (b.parent === false && 'route' in b && '/' + b.route === activeLink) {
              result = [
                {
                  url: ('route' in b) ? b.route : false,
                  title: a.displayName + ' / ' + b.displayName,
                  breadcrumbs: ('breadcrumbs' in b) ? b.breadcrumbs : true,
                  type: 'item',
                  active: activeLink,
                  hideInBreadcrumb: ('hideInBreadcrumb' in b) ? b.hideInBreadcrumb : false,
                }
              ];
              title = b.displayName;
            }
          });
        }
      }
    });
    this.navigationList = result;
    this.title = title;
    this.titleService.setTitle(title + ' | Kotak CBC Portal');
  }
}
