import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { embedDashboard } from 'superset-ui-sdk/embedded-sdk';
import { environment } from 'src/environments/environment';
import * as Notiflix from 'notiflix';
import { Router } from '@angular/router';

@Component({
  selector: 'app-agent-onboarding-dash',
  templateUrl: './agent-onboarding-dash.component.html',
  styleUrls: ['./agent-onboarding-dash.component.scss']
})
export class AgentOnboardingDashComponent implements OnInit {
  token: any;
  loadResponse: boolean = false;
  supersetId:any;
  currUrl: any;
  constructor(private http: HttpClient,private router:Router) {
    this.currUrl= this.router.url;
   }

  ngOnInit(): void {
    this.fetchGuestTokenFromBackend()

  }


  async fetchGuestTokenFromBackend() {
    Notiflix.Loading.arrows('Loading...');
    const data = environment.supersetReqBody
    let header = new HttpHeaders().set("Access-Control-Allow-Origin", "*");
    this.http.post(`${environment.superSetUrl}/api/v1/security/login`, data, { headers: header }).subscribe(
      async (res: any) => {
        if(res){
          this.getGuestToken(res.access_token);
        }else{
          this.router.navigate(['distributor/dashboard']);
          Notiflix.Notify.failure('Something Went Wrong ! Please try Again.');
          Notiflix.Loading.remove();
        }
      },
      err => {
        Notiflix.Loading.remove();
        this.router.navigate(['distributor/dashboard']);
        Notiflix.Notify.failure('Something Went Wrong ! Please try Again.');
        //console.log(err.error);
      }
    )
  }
  getGuestToken(token: string) {
    if(this.currUrl.includes('/agent-dashboard')){
      this.supersetId = environment.onboardingDashSupersetId;
    }else  if(this.currUrl.includes('/twowheeler-dashboard')){
      this.supersetId = environment.twowheelerDashSupersetId;
    } else  if(this.currUrl.includes('/aeps-dashboard')){
      this.supersetId = environment.aepsDashSupersetId;
    }

    const id = this.supersetId
    let reqBody = {
      "resources": [
        {
          "id": id,
          "type": "dashboard"
        }
      ],
      // "rls": [{ "clause": "username='iserveutech'" }],
      "rls": [],
      "user": {
        "username": JSON.parse(sessionStorage.getItem('dashboardData')).userName,
      }
    };
    let header = new HttpHeaders({
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    });
    this.http.post(`${environment.superSetUrl}/api/v1/security/guest_token/`, reqBody, { headers: header }).subscribe(
      (res:any) => {
        if(res){

          Notiflix.Loading.remove();
          this.token = JSON.parse(JSON.stringify(res)).token;
          embedDashboard({
            id: this.supersetId,
            supersetDomain: `${environment.superSetUrl}`,
            mountPoint: document.getElementById("cbc-dashboard"),
            fetchGuestToken: async () => this.token,
            dashboardUiConfig: {
              hideTitle: false, hideTab: false,
            },
            debug: true,
          });

          this.loadResponse = true;
        }else{
          this.router.navigate(['distributor/dashboard']);
          Notiflix.Notify.failure('Something Went Wrong ! Please try Again.');
          Notiflix.Loading.remove();
        }
      },
      err => {
        //console.log(err.error);
        Notiflix.Loading.remove();
        this.router.navigate(['distributor/dashboard']);
        Notiflix.Notify.failure('Something Went Wrong ! Please try Again.');
      }
    )
  }




}
