<p style="cursor: default"></p>
<div class="main_container">
  <div class="container">
    <div class="dashboard">
      <h4> Summary Dashboard</h4>
    </div>
    <div class="content" *ngIf="contentshow">
      <div class="box_style agent_dash " (click)="agent_dash('agent-dashboard')">
        <p>Agent Onboarding</p>
        <div class="box_style_list list" >
          <ul>
            <li>Total No. of Agents :

              <span>{{summaryDashResponse.agent_onboarding.total_agents_uploaded ?? 'N/A'}}</span>
            </li>
            <li>No. of Approval Pending SO :

              <span>{{summaryDashResponse.agent_onboarding.approval_pending_at_so ?? 'N/A'}}</span>
            </li>
            <li>No. of Approval Pending HO :

              <span>{{summaryDashResponse.agent_onboarding.approval_pending_at_ho ?? 'N/A'}}</span>
            </li>
            <li>No. of Approved Agents :

              <span>{{summaryDashResponse.agent_onboarding.approved_agents ?? 'N/A'}}</span>
            </li>
            <li>No. of Rejected :

              <span>{{summaryDashResponse.agent_onboarding.rejected ?? 'N/A'}}</span>
            </li>
            <li>KYC Not Started :

              <span>{{summaryDashResponse.agent_onboarding.kyc_not_started ?? 'N/A'}}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="box_style_loan loan_dash" >
        <p>LOAN</p>
        <div class="loan_list">
          <div class=" box_style two_wheeler_loan" (click)="agent_dash('twowheeler-dashboard')">
            <p>TWO WHEELER</p>
            <div class=" list" >
              <ul>
                <li>Total Lead :

                  <span>{{summaryDashResponse.loan.two_wheeler.TotalLead ?? 'N/A'}}</span>
                </li>
                <li>Approved/Sanctioned :

                  <span>{{summaryDashResponse.loan.two_wheeler.approveTxn ?? 'N/A'}}</span>
                </li>
                <li>Disbursed :

                  <span>{{summaryDashResponse.loan.two_wheeler.disburseTxn ?? 'N/A'}}</span>
                </li>
                <li>Rejected :

                  <span>{{summaryDashResponse.loan.two_wheeler.rejectTxn ?? 'N/A'}}</span>
                </li>
                <li>Canceled :

                  <span>{{summaryDashResponse.loan.two_wheeler.CancelledTxn ?? 'N/A'}}</span>
                </li>
                <li>Initiated :

                  <span>{{summaryDashResponse.loan.two_wheeler.InitiatedTxn ?? 'N/A'}}</span>
                </li>
                <li>New :

                  <span>{{summaryDashResponse.loan.two_wheeler.NewTxn ?? 'N/A'}}</span>
                </li>
                <li>Failed :

                  <span>{{summaryDashResponse.loan.two_wheeler.FailedTxn ?? 'N/A'}}</span>
                </li>
                <li>Refer :

                  <span>{{summaryDashResponse.loan.two_wheeler.ReferTxn ?? 'N/A'}}</span>
                </li>
                <li>Null :

                  <span>{{summaryDashResponse.loan.two_wheeler.NullTxn ?? 'N/A'}}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="box_style cv_loan" >
            <p>CV/CE</p>
            <div class=" list" >
              <ul>
                <li>Total Lead :

                  <span>{{summaryDashResponse?.cv ?? 'N/A'}}</span>
                </li>
                <li>Approved/Sanctioned :

                  <span>{{summaryDashResponse?.cv ?? 'N/A'}}</span>
                </li>
                <li>Disbursed :

                  <span>{{summaryDashResponse?.cv ?? 'N/A'}}</span>
                </li>
                <li>Rejected :

                  <span>{{summaryDashResponse?.cv ?? 'N/A'}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="box_style aeps_dash" (click)="agent_dash('aeps-dashboard')" >
        <p>AePS</p>
        <div class="box_style_list list">
          <ul>
            <li>Total No. of Txns : <span>{{summaryDashResponse.aeps.todaystxn ?? 'N/A' }}</span></li>

            <li>Total No. of Success Txns :

              <span>{{ summaryDashResponse.aeps.successTxn ?? 'N/A' }}</span>
            </li>
            <li>Total No. of Failed Txns :

              <span>{{summaryDashResponse.aeps.failedTxn ?? 'N/A' }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="main_container" *ngIf="production_url.includes('https://kotakfiplatform.bharatkioskbanking.com/')">
  <p>Dashboard Works!!!</p>
</div> -->
<!-- <router-outlet></router-outlet> -->
