import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as Notiflix from 'notiflix';
import { assetUrl } from 'src/single-spa/asset-url';
import { AppService } from './app.service';
import { NavigationItem } from './navigation';
import { allApi } from './appApi';

export interface NavItem {
  displayName?: string;
  disabled?: boolean;
  iconName?: string;
  route?: string;
  color?: string;
  statusName?: string;
  show?: any;
  parent?: boolean;
  children?: NavItem[];
  kycStatus: boolean
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  change_password_form: FormGroup;
  title = 'kotakdashboard';
  demo: any;
  showChild: boolean = false;
  currentIndex: any;
  hamburger_change: boolean = false;
  mobile_header: boolean = false;
  hamburger_changemble: boolean = false;
  avtar: any = {
    right_logo: assetUrl('images/user.png'),
    left_logo: assetUrl('images/saarthi_logo.png'),
    right_inner_logo: assetUrl('images/trans_bg_logo.png')
  }
  wallet: any = {
    w1: assetUrl('images/wallet1.svg'),
    w2: assetUrl('images/wallet2.svg')
  }
  dropdown: boolean = false;
  userName: any = ''
  brand_name: any = '';
  wallet1: any = 1234;
  wallet2: any = 9089;
  angle: boolean = true;
  top_ang: boolean = true;
  otp_mod: boolean = false;
  @ViewChild('changePassword', { static: false }) private change_password: any;
  otp_btn: boolean = true;
  fieldTypePass: boolean = true;
  fieldTypePass1: boolean = true;
  fieldTypePass2: boolean = true;
  pwdVal: boolean = false;
  emptyFld: boolean = false;

  isPasswordResetRequired: any;
  input_otp: any;
  pwdMatched: boolean = false;



  constructor(
    private _appService: AppService,
    private _router: Router,
    private _navigationItem: NavigationItem
  ) {

    this.change_password_form = new FormGroup({
      old_pwd: new FormControl('', [Validators.required]),
      new_pwd: new FormControl('', [Validators.required]),
      cnf_pwd: new FormControl('', [Validators.required])
    });
  }

  async ngOnInit() {
    this.userName = JSON.parse(sessionStorage.getItem('dashboardData')).userName;
    localStorage.setItem('user_name', this.userName);
    this.showNavitems();
    this.isPasswordResetRequired = sessionStorage.getItem('isPasswordResetRequired');
  }
  togglePass(e: any) {
    if (e == 0) {
      this.fieldTypePass = !this.fieldTypePass;
    } else if (e == 1) {
      this.fieldTypePass1 = !this.fieldTypePass1;
    } else {
      this.fieldTypePass2 = !this.fieldTypePass2;
    }
  }
  noSpecialChar(event: any) {
    return event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122 || event.charCode >= 48 && event.charCode <= 57;
  }
  show_dropdown = () => {
    this.dropdown = !this.dropdown;
  }
  navCollapse(e: any) {
    if (this.currentIndex === e) {
      this.currentIndex = 90;
      this.angle = true;
    } else {
      this.currentIndex = e;
    }
  }
  toggle_bar() {
    this.hamburger_change = !this.hamburger_change
  }
  ngAfterViewInit() {
    if (this.isPasswordResetRequired == 'true') {
      this.change_password.show()
    }
  }

  showNavitems = () => {
    const navitems: NavItem[] = this._navigationItem.navitems;
    this.demo = navitems;

  }

  logout = () => {
    localStorage.removeItem('user_name');
    localStorage.removeItem('brand_name');
    localStorage.removeItem('admin_name');
    localStorage.removeItem('shop_name');
    localStorage.removeItem('columnCount');
    localStorage.removeItem('u_det');
    sessionStorage.clear();
    let url = localStorage.getItem('logouturl');
    if (url.startsWith('localhost')) {
      window.location.href = 'http://' + url;
    }
    else {
      window.location.href = 'https://' + url;
    }
  }

  resend_password = () => {
    this.update_password();
  }

  changePwd_modal = () => {
    this.dropdown = false;
    this.otp_mod = false;
    this.otp_btn = true;
    this.change_password_form.reset();
    this.change_password.show();
  }
  check_pwd = (e: any) => {
    let newPwd = this.change_password_form.get('new_pwd').value;
    if (newPwd) {
      this.pwdVal = newPwd.startsWith(e.target.value);
      if (this.pwdVal) {
        this.pwdMatched = newPwd == e.target.value ? true : false;
      } else {
        this.pwdMatched = false;
      }
    } else {
      this.emptyFld = true;
      this.change_password_form.controls['cnf_pwd'].reset();
    }
  }
  removeError = (e: any) => {
    if (e) {
      this.emptyFld = false;
    } else {
      this.emptyFld = true;
    }
  }

  update_password = () => {
    Notiflix.Loading.arrows('Loading...');
    let dis = this;
    let reqBody = {
      "oldPassword": this.change_password_form.get('old_pwd').value,
      "newPassword": this.change_password_form.get('new_pwd').value,
      "otp": this.input_otp,
    };
    let updatePassword = allApi.url.updatePassword;
    this._appService.post_method(updatePassword, reqBody).pipe().subscribe({
      next(res: any) {
        Notiflix.Loading.remove();
        Notiflix.Notify.success("Otp Send Successfully");
        dis.otp_mod = true;
      }, error(err: any) {
        Notiflix.Loading.remove();
        dis.otp_mod = false;
        if (err.error.statusCode == -1) {
          Notiflix.Notify.failure(err.error.statusDesc ? err.error.statusDesc : err.error.message);
        } else {
          Notiflix.Notify.failure(err.error.message);
        }
      }
    })
  }

  changePass() {
    let dis = this;
    Notiflix.Loading.arrows('Loading...');
    let reqBody = {
      "oldPassword": this.change_password_form.get('old_pwd').value,
      "newPassword": this.change_password_form.get('new_pwd').value,
      "otp": this.input_otp,
    };
    //console.log(this.input_otp);
    let changePassword = allApi.url.changePassword;
    dis._appService.post_method(changePassword, reqBody).pipe().subscribe({
      next(res: any) {
        if (res.statusCode == -1) {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(res.statusDesc);
        } else {
          dis.otp_mod = false;
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Password Updated...");
          dis.logout();
        }
      },
      error(err: any) {
        Notiflix.Loading.remove();
        this.otp_mod = false;
        Notiflix.Notify.failure(err.error.statusDesc);
        if (err.error.statusCode == -2) {
          setTimeout(() => {
            dis.logout();
          }, 2000);
        }
      }
    })
  }

  getOtpValue = (e: any) => {
    this.input_otp = e;
    if (e.length == 6) {
      this.otp_btn = false;
    } else {
      this.otp_btn = true;
    }
  }

  close_modal = (e: any) => {
    if (e == 'change_pwd') {
      this.change_password.hide();
    }
  }
}

