<!doctype html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta http-equiv="Content-Language" content="en">
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <link rel="stylesheet" href="//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css">
  <title>Kotak Bank</title>
  <meta name="viewport"
    content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, shrink-to-fit=no" />
  <meta name="description" content="Examples of just how powerful ArchitectUI really is!">

  <meta name="msapplication-tap-highlight" content="no">
</head>

<body>
  <div class="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar"
    [ngClass]="hamburger_change ?'closed-sidebar': hamburger_changemble?'sidebar-mobile-open':''">
    <div class="app-header header-shadow">
      <div class="app-header__logo m-0 p-0">
        <div class="logo-src dfcr w-100 m-0 p-0">
          <img [src]="avtar.left_logo" alt="" class="left_logo p-0 m-0" height="65">
          <h5 class="brand pl-1 pt-0 mt-0 pb-0 mb-0">Kotak Mahindra Bank</h5>
        </div>
        <div class="header__pane ml-auto">
          <div>
            <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
              [ngClass]="hamburger_change?'is-active':''" (click)="hamburger_change=!hamburger_change"
              data-class="closed-sidebar">
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="app-header__mobile-menu">
        <div>
          <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav"
            [ngClass]="hamburger_changemble?'is-active':''" (click)="hamburger_changemble=!hamburger_changemble">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
      <div class="app-header__menu">
        <span>
          <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
            (click)="mobile_header = !mobile_header">
            <span class="btn-icon-wrapper">
              <em class="fa fa-ellipsis-v fa-w-6"></em>
            </span>
          </button>
        </span>
      </div>
      <div class="app-header__content" [ngClass]="mobile_header?'header-mobile-open':''">
        <div class="app-header-right">
          <div class="header-dots">
            <div class="dropdown">
              <button type="button" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown"
                class="p-0 m-3 btn navBtns btn-link" data-toggle="tooltip" data-placement="right" title="Notification">
                <span class="icon-wrapper icon-wrapper-alt rounded-circle wal_bal">
                  <span class="icon-wrapper-bg bg-primary"></span>
                  <em class="icon bg-icon fa fa-bell" style="pointer-events: none;"></em>
                </span>
              </button>
              <button type="button" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown"
                class="p-0 m-3 btn navBtns btn-link" data-toggle="tooltip" data-placement="right" title="Notification">
                <span class="icon-wrapper icon-wrapper-alt rounded-circle wal_bal">
                  <span class="icon-wrapper-bg bg-primary"></span>
                  <em class="fa fa-credit-card" style="pointer-events: none;"></em>
                </span>
              </button>
            </div>
          </div>
          <div class="header-btn-lg pr-0">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left">
                  <div class="btn-group">
                    <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="avtar"
                      style="cursor: pointer;">
                      <img width="30" height="30" [src]="avtar.right_logo" alt="Avtar" (click)="show_dropdown()">
                    </a>
                    <div tabindex="-1" role="menu" aria-hidden="true" id="demo"
                      class="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-right"
                      [ngClass]="dropdown ? 'show_dropdown' : 'hide_dropdown'">
                      <div class="dropdown-menu-header">
                        <div class="dropdown-menu-header-inner bg-info">
                          <div class="menu-header-content text-right">
                            <div class="dfsb">
                              <div class="">
                                <img width="100" [src]="avtar.right_inner_logo" alt="">
                              </div>
                              <button class="closeDropDownBtn" title="Close">
                                <em class="fa fa-times-circle" (click)="show_dropdown()"></em>
                              </button>
                            </div>
                          </div>
                          <div class="menu-header-content text-left">
                            <div class="widget-content p-0">
                              <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                  <div class="widget-heading" style="color: #FF0000;">Hi, {{userName}}</div>
                                </div>
                                <div class="widget-content-right mr-2">
                                  <button class="btn-pill btn-shadow btn-shine btn btn-focus"
                                    (click)="logout()">Logout</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="scroll-area-xs" style="height: 150px;">
                        <div class="scrollbar-container ps">
                          <ul class="nav flex-column">
                            <li class="nav-item-header nav-item">Activity</li>
                            <li class="nav-item">
                              <a href="javascript:void(0);" class="nav-link">Setting
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="javascript:void(0);" class="nav-link" (click)="changePwd_modal()">Change
                                Password</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Theme Setting Code -->
    <div class="app-main" [ngClass]="hamburger_change?'closed-sidebar':''">
      <div class="app-sidebar sidebar-shadow">
        <div #sidenav class="scrollbar-sidebar">
          <div class="app-sidebar__inner">
            <ul class="vertical-nav-menu">
              <li class="app-sidebar__heading"></li>
              <li *ngFor="let item of demo;let i=index">
                <a [target]="item.target ? '_blank' : '_self'" (click)="navCollapse(i)"
                  *ngIf="!item.parent && item.kycStatus" [routerLinkActive]="'mm-active'" [routerLink]="item.route"
                  style="text-decoration: none; padding-left: 1rem; color: white; font-weight: 400; display: flex; align-items: center; gap: 14px;">
                  <em class="metismenu-icon" [ngClass]="item.iconName"></em><span class="">{{ item.displayName
                    }}</span>
                </a>
                <!-- For Children contain Parent -->
                <a [target]="item.target ? '_blank' : '_self'" (click)="navCollapse(i)"
                  *ngIf="item.parent && item.kycStatus"
                  style="text-decoration: none; padding-left: 1rem; color: white; font-weight: 400;display: flex; align-items: center; gap: 14px;">
                  <em class="metismenu-icon" [ngClass]="item.iconName"></em><span class="">{{ item.displayName
                    }}</span>
                  <em class="metismenu-state-icon fa fa-angle-down rotate icon-rotate"
                    *ngIf="angle && i != currentIndex"></em>
                  <em class="metismenu-state-icon fa fa-angle-right rotate icon-rotate"
                    *ngIf=" i == currentIndex"></em>
                </a>

                <!-- For Children -->
                <ul class="mm-show" *ngIf="item.parent && item.type && i == currentIndex">
                  <li class="mm-active" *ngFor="let child of item.children;">
                    <a [target]="item.target ? '_blank' : '_self'" [routerLink]="[child.route]"
                      [routerLinkActive]="'mm-active'" style="text-decoration: none;">
                      <span class="pcoded-mtext" *ngIf="item.children">{{child.displayName}}</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="app-main__outer w-100">
        <div class="app-main__inner">
          <div class="mb-4" style="cursor: default;">
            <app-breadcrumb></app-breadcrumb>
          </div>
          <router-outlet>
            <app-spinner></app-spinner>
          </router-outlet>
        </div>
      </div>
    </div>
  </div>
  <div class="app-drawer-overlay d-none animated fadeIn"></div>


  <!-- modal for change Password starts -->
  <app-ui-modal #changePassword [containerClick]="false" class="custom_modal_height">
    <div class="app-modal-header modalHead m-0 p-0">
      <h4 class="modal-title">
        <span *ngIf="!otp_mod">Change Password</span>
        <span *ngIf="otp_mod">Enter OTP</span>
      </h4>
      <button *ngIf="isPasswordResetRequired == 'false'" type="button" class="close basic-close text-right close_style"
        data-dismiss="modal" aria-label="Close" (click)="changePassword.hide()"><span
          aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
      <div class="changePwd" *ngIf="!otp_mod">
        <form [formGroup]="change_password_form">
          <div class="inpts">
            <label>Enter Old Password <span class="text-danger">*</span> :</label>
            <input type="password" maxlength="20" formControlName="old_pwd" class="form-control mt-2"
              [type]="fieldTypePass?'password':'text'">
            <em class="fa myPos" [ngClass]="{'fa-eye-slash': fieldTypePass,'fa-eye': !fieldTypePass}"
              (click)="togglePass(0)"></em>
            <label>Enter New Password <span class="text-danger">*</span> :</label>
            <input type="password" (input)="removeError($event)" maxlength="20" formControlName="new_pwd"
              class="form-control mt-2" [type]="fieldTypePass1?'password':'text'">
            <em class="fa myPos" [ngClass]="{'fa-eye-slash': fieldTypePass1,'fa-eye': !fieldTypePass1}"
              (click)="togglePass(1)"></em>
            <div *ngIf="emptyFld" class="text-danger p-1">First Enter New Password</div>
            <label>Confirm New Password <span class="text-danger">*</span> :</label>
            <input type="password" maxlength="20" (input)="check_pwd($event)" formControlName="cnf_pwd"
              class="form-control mt-2" [type]="fieldTypePass2?'password':'text'">
            <em class="fa myPos" [ngClass]="{'fa-eye-slash': fieldTypePass2,'fa-eye': !fieldTypePass2}"
              (click)="togglePass(2)"></em>
            <div *ngIf="!pwdVal && change_password_form.get('cnf_pwd').touched" class="text-danger p-1">Password did not
              match</div>
          </div>
        </form>
      </div>
      <div class="validate_otp" *ngIf="otp_mod">
        <ng-otp-input (onInputChange)="getOtpValue($event)" [config]="{ length: 6, allowNumbersOnly: true }"
          maxlength="6"></ng-otp-input>
      </div>
    </div>
    <div class="app-modal-footer">
      <div class="btns mt-2">
        <button class="btn succ_btn all_btn mx-1" *ngIf="!otp_mod" (click)="update_password()"
          [disabled]="change_password_form.invalid || !pwdMatched">Send OTP</button>
        <button class="btn succ_btn all_btn mx-1" *ngIf="otp_mod" (click)="resend_password()">Resend OTP</button>
        <button class="btn succ_btn all_btn mx-1" *ngIf="otp_mod" [disabled]="otp_btn"
          (click)="changePass()">Update</button>
      </div>
    </div>
  </app-ui-modal>
  <!-- modal for change Password ends -->
</body>

</html>
