import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Confirm, Loading, Notify } from 'notiflix';
import { HttpClient } from '@angular/common/http';
import { allApi } from '../appApi';
import { AppService } from '../app.service';
@Component({
  selector: 'app-pre-onboarding-details',
  templateUrl: './pre-onboarding-details.component.html',
  styleUrls: ['./pre-onboarding-details.component.scss'],
})
export class PreOnboardingDetailsComponent implements OnInit {
  searchForm: FormGroup;
  userForm: FormGroup;
  fetchedUserDetails: any;
  dashBoardData: any;
  emailMasking: string;
  mobileMasking: string;
  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private service: AppService
  ) {}

  ngOnInit(): void {
    this.dashBoardData = JSON.parse(sessionStorage.getItem('dashboardData'));
    this.searchForm = this.formBuilder.group({
      searchType: ['email'],
      email: [
        '',
        [
          Validators.email,
          Validators.required,
          Validators.pattern(
            '^([\\w\\d\\.\\-]+)@([\\w\\d\\-]+)\\.([a-zA-Z]{2,20})(\\.[a-zA-Z]{2,20})?$'
          ),
        ],
      ],
      phone: ['', [Validators.pattern(/^[6-9]\d{9}$/), Validators.required]],
    });

    this.userForm = this.formBuilder.group({
      name: [''],
      email: ['', [Validators.email]],
      mobile: ['', [Validators.pattern(/^[6-9]\d{9}$/)]],
    });
  }

  search() {
    let reqBody: any;
    if (
      this.searchForm.value.email &&
      this.searchForm.controls['email'].valid
    ) {
      reqBody = { email: this.searchForm.value.email };
    } else {
      reqBody = { mobileNumber: this.searchForm.value.phone };
    }
    this.fetchedUserDetails = null;
    Loading.arrows();
    this.service.post_method(allApi.url.fetch_email, reqBody).subscribe({
      next: (res: any) => {
        Loading.remove();
        if (res.status == 0) {
          this.fetchedUserDetails = res;
          if (this.fetchedUserDetails.email) {
            this.emailMasking = this.service.masking_method(
              this.fetchedUserDetails.email,
              'email'
            );
          }
          if (this.fetchedUserDetails.mobileNumber) {
            this.mobileMasking = this.service.masking_method(
              this.fetchedUserDetails.mobileNumber,
              'mobile'
            );
          }
          this.userForm.patchValue({
            name: this.fetchedUserDetails.name,
          });
          Notify.success(res.statusDesc);
        } else {
          Notify.failure(res.statusDesc);
        }
      },
      error: (error: any) => {
        Notify.failure('Server error!');
        Loading.remove();
      },
    });
  }

  get search_form() {
    return this.searchForm.controls;
  }
  optionChange() {
    this.searchForm.patchValue({
      email: '',
      phone: '',
    });
    this.reset();
    this.fetchedUserDetails = null;
  }
  updateData() {
    if (
      this.dashBoardData.userName == this.fetchedUserDetails.cbcUserName &&
      this.fetchedUserDetails.kycStatus == 3
    ) {
      if (
        (this.userForm.value.email && this.userForm.controls['email'].valid) ||
        (this.userForm.value.mobile && this.userForm.controls['mobile'].valid)
      ) {
        let reqBody = {
          mobileNumber: this.fetchedUserDetails.mobileNumber.toString(),
          newMobileNumber: this.userForm.value.mobile
            ? this.userForm.value.mobile
            : this.fetchedUserDetails.mobileNumber.toString(),
          email: this.fetchedUserDetails.email,
          newEmail: this.userForm.value.email
            ? this.userForm.value.email
            : this.fetchedUserDetails.email,
          userName: this.fetchedUserDetails.userName,
        };
        Confirm.show(
          'Confirm',
          'Please confirm if you want to update the data?',
          'Yes',
          'No',
          () => {
            Loading.arrows();
            this.http.post(allApi.url.update_email, reqBody).subscribe({
              next: (res: any) => {
                Loading.remove();
                if (res.status == 0) {
                  if (
                    res.statusDesc ==
                    'Both New Mobile Number and New Email Id  is Already present'
                  ) {
                    Notify.info(
                      this.userForm.value.mobile && this.userForm.value.email
                        ? 'Entered Mobile number and Email Id Already present'
                        : this.userForm.value.mobile
                        ? 'Entered Mobile number Already present'
                        : this.userForm.value.email
                        ? 'Entered Email Id Already present'
                        : '',
                      { timeout: 5000 }
                    );
                    this.reset();
                  } else {
                    Notify.success(
                      this.userForm.value.mobile && this.userForm.value.email
                        ? 'Mobile number and Email Id updated successfully'
                        : this.userForm.value.mobile
                        ? 'Mobile number updated successfully'
                        : this.userForm.value.email
                        ? 'Email Id updated successfully'
                        : '',
                      { timeout: 5000 }
                    );
                    this.optionChange();
                    this.reset();
                    this.searchForm.patchValue({
                      searchType: 'user',
                    });
                    this.optionChange();
                    this.searchForm.get('searchValue').reset();
                    this.fetchedUserDetails = null;
                  }
                } else {
                  Notify.failure(res.statusDesc, { timeout: 5000 });

                  this.reset();
                  this.searchForm.patchValue({
                    searchType: 'user',
                  });
                  this.optionChange();
                  this.searchForm.get('searchValue').reset();
                  this.fetchedUserDetails = null;
                }
              },
              error: (error: any) => {
                Notify.failure('Server error!');
                Loading.remove();
              },
            });
          }
        );
      } else {
        Notify.failure('Please enter valid email or mobile number!');
      }
    } else if (this.fetchedUserDetails.kycStatus != 3) {
      Notify.failure(
        'User KYC journey has completed, details can’t be updated.'
      );
    } else {
      Notify.failure('You are not authorized to update this agent!');
    }
  }

  reset() {
    this.userForm.patchValue({
      email: '',
      mobile: '',
    });
  }
  onlyNumberKey(event: any) {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();

      return false;
    } else {
      return true;
    }
  }
}
