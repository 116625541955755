<form [formGroup]="searchForm" class="form-container">
  <div class="form-group-align">
    <div class="form-group">
      <label class="form-label">
        <div class="form-radio-wrapper">
          <input
            type="radio"
            formControlName="searchType"
            value="email"
            class="form-radio"
            (change)="optionChange()"
          />
          Search by Email ID
        </div>
      </label>
      <div class="input-error-block">
        <input
          type="text"
          formControlName="email"
          class="form-input"
          placeholder="Enter Email ID"
          [readonly]="search_form['searchType'].value != 'email'"
          (keydown.space)="$event.preventDefault()"
        />
        <div
          *ngIf="
            search_form['email'].touched &&
            search_form['email'].hasError('required') && search_form['searchType'].value == 'email'
          "
          class="error-message"
        >
          Email ID must be Requried.
        </div>
        <div
          *ngIf="
            search_form['email'].touched &&
            search_form['email'].hasError('pattern')
          "
          class="error-message"
        >
          Enter Valid Email ID.
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="form-label">
        <div class="form-radio-wrapper">
          <input
            type="radio"
            formControlName="searchType"
            value="phone"
            class="form-radio"
            (change)="optionChange()"
          />
          Search by Mobile Number
        </div>
      </label>
      <div class="input-error-block">
        <input
          type="text"
          formControlName="phone"
          class="form-input"
          placeholder="Enter Mobile Number"
          maxlength="10"
          [readonly]="search_form['searchType'].value != 'phone'"
          (keydown.space)="$event.preventDefault()"
          (keypress)="onlyNumberKey($event)"
        />
        <div
          *ngIf="search_form['phone'].touched && search_form['searchType'].value == 'phone' && search_form['phone'].hasError('required')"
          class="error-message"
        >
          Mobile Number Must be Requried.
        </div>
        <div
          *ngIf="search_form['phone'].touched && search_form['phone'].hasError('pattern')"
          class="error-message"
        >
          Enter Valid Mobile Number.
        </div>
      </div>
    </div>
  </div>
  <button
    (click)="search()"
    class="form-button button-margin"
    [ngClass]="!(searchForm.value.email && searchForm.controls['email'].valid) &&
    !(searchForm.value.phone && searchForm.controls['phone'].valid) ? 'disable' : ''"
    [disabled]="
      !(searchForm.value.email && searchForm.controls['email'].valid) &&
      !(searchForm.value.phone && searchForm.controls['phone'].valid)
    "
  >
    Search
  </button>
</form>
<div *ngIf="fetchedUserDetails">
  <div class="table-container">
    <table aria-describedby="user table" class="table-content">
      <thead>
        <tr>
          <th scope="col">Username</th>
          <th scope="col">Name</th>
          <th scope="col">Email ID</th>
          <th scope="col">Mobile Number</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ fetchedUserDetails.userName }}</td>
          <td>{{ fetchedUserDetails.name }}</td>
          <td>{{ emailMasking }}</td>
          <td>{{ mobileMasking }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <form [formGroup]="userForm">
    <h4 class="update-form-header">Update details below :</h4>
    <div class="update-form-container">
      <!-- <div class="input-wrapper">
        <label for="name">Name :</label>
        <input
          type="text"
          id="name"
          placeholder="name"
          formControlName="name"
          class="form-input"
          [readonly]="true"
        />
        <div
          *ngIf="
            userForm.get('name').hasError('required') &&
            userForm.get('name').touched
          "
        >
          Name is required.
        </div>
      </div> -->

      <div class="input-wrapper">
        <label for="email">Email ID:</label>
        <input
          type="email"
          placeholder="Enter Email ID"
          id="email"
          formControlName="email"
          class="form-input"
        />

        <div
          *ngIf="
            userForm.get('email').hasError('email') &&
            userForm.get('email').touched
          "
          class="error-message"
        >
          Invalid Email ID.
        </div>
      </div>

      <div class="input-wrapper">
        <label for="mobile">Mobile Number:</label>
        <input
          type="text"
          id="mobile"
          placeholder="Enter Mobile Number"
          formControlName="mobile"
          class="form-input"
          (keydown.space)="$event.preventDefault()"
          (keypress)="onlyNumberKey($event)"
          maxlength="10"
        />

        <div
          *ngIf="
            userForm.get('mobile').hasError('pattern') &&
            userForm.get('mobile').touched
          "
          class="error-message"
        >
        Please Enter a 10-digit Number Starting from 6 to 9.
        </div>
      </div>
    </div>
    <div class="button-container">
      <button
        [disabled]="(userForm.get('mobile')?.value=='' && userForm.get('email')?.value=='') || userForm.invalid"
        [ngClass]="(userForm.get('mobile')?.value=='' && userForm.get('email')?.value=='') || userForm.invalid   ? 'disable' : ''"
        class="form-button"
        style="margin-right: 5px"
        (click)="reset()"
      >
        Reset
      </button>
      <button
      [disabled]="(userForm.get('mobile')?.value=='' && userForm.get('email')?.value=='') || userForm.invalid"
        [ngClass]="(userForm.get('mobile')?.value=='' && userForm.get('email')?.value=='') || userForm.invalid ? 'disable' : ''"
        class="form-button"
        (click)="updateData()"
      >
        Update
      </button>
    </div>
  </form>
  <!-- <app-spinner></app-spinner> -->
</div>
