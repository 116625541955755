// import { ReplaySubject } from 'rxjs';
// import { AppProps } from 'single-spa';

// export const singleSpaPropsSubject = new ReplaySubject<SingleSpaProps>(1);

// // Add any custom single-spa props you have to this type def
// // https://single-spa.js.org/docs/building-applications.html#custom-props
// export type SingleSpaProps = AppProps & {};


import { ReplaySubject } from 'rxjs';
import { AppProps } from 'single-spa';

// Add any custom single-spa props here
export type SingleSpaProps = AppProps & {
  customProp?: string; // Example custom property
};

export const singleSpaPropsSubject = new ReplaySubject<SingleSpaProps>(1);
