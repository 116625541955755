import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { AngularFireModule } from '@angular/fire';
// import { AngularFirestore } from '@angular/fire/firestore';
import "firebase/firestore"
// import { AngularFireModule } from '@angular/fire';
import { AuthInterceptor } from './auth/auth.interceptor';
import { SharedModule } from './theme/shared/shared.module';
import { NgOtpInputModule } from 'ng-otp-input';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { NavigationItem } from './navigation';
import { AgentOnboardingDashboardComponent } from './agent-onboarding-dashboard/agent-onboarding-dashboard.component';
import { PreOnboardingDetailsComponent } from './pre-onboarding-details/pre-onboarding-details.component';
import { NgxEnvModule } from '@ngx-env/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterLink, RouterOutlet } from '@angular/router';
import { AgentOnboardingDashComponent } from './dashboard/agent-onboarding-dash/agent-onboarding-dash.component';


const fireBaseConfig = {
  apiKey: "AIzaSyCUEgt83z-alaZJdkcHBtWxf0qsyH0jI_w",
  authDomain: "fin-kotak.firebaseapp.com",
  projectId: "fin-kotak",
  storageBucket: "fin-kotak.appspot.com",
  messagingSenderId: "179491372460",
  appId: "1:179491372460:web:4a46f803ecaf5276754e09"
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    AgentOnboardingDashboardComponent,
    PreOnboardingDetailsComponent,
    AgentOnboardingDashComponent,

  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    // NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgOtpInputModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(fireBaseConfig),
    AngularFirestoreModule,
    SharedModule,
    NgxEnvModule,
    RouterOutlet,
    RouterLink
    // AngularFireMessagingModule
  ],
  providers: [
    NavigationItem,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
