import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Notiflix from 'notiflix';
import { allApi } from '../appApi';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  threeMonthsAgo: any;
  summaryDashResponse: any;
  agent_dashValues: any[];
  loan_twowheeler_data: any[];
  contentshow: boolean;
  constructor(private router: Router, private http: HttpClient) {}
  ngOnInit(): void {
    Notiflix.Loading.arrows('Loading...');

    const currentDate = new Date();
    const threeMonthsAgoDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 3,
      currentDate.getDate()
    );
    const fromDate = `${threeMonthsAgoDate.getFullYear()}-${(
      threeMonthsAgoDate.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}-${threeMonthsAgoDate
      .getDate()
      .toString()
      .padStart(2, '0')}`;
    const toDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
    const reqBody = {
      $2: JSON.parse(sessionStorage.getItem('dashboardData')).userName,
      $4: '2023-02-14',
      $5: toDate,
    };

    this.http.post(allApi.url.dashboard_cbc, reqBody).subscribe(
      (res: any) => {
        if (res) {
          this.contentshow = true;
          this.summaryDashResponse = res;
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure('Something Went Wrong.');
        }
      },
      (err: any) => {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure('Something Went Wrong.');
      }
    );
  }

  agent_dash(item: any) {
    switch (item) {
      case 'agent-dashboard':
        this.router.navigateByUrl(`distributor/dashboard/${item}`);
        break;
      case 'twowheeler-dashboard':
        this.router.navigateByUrl(`distributor/dashboard/${item}`);
        break;
      case 'aeps-dashboard':
        this.router.navigateByUrl(`distributor/dashboard/${item}`);
        break;
      default:
        this.router.navigateByUrl(`distributor/dashboard`);
        break;
    }
  }
}
