import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  logOutTimer:any;
  constructor(private router:Router) { }
  canActivate(): boolean {
    if (sessionStorage.getItem('userType') != 'ROLE_MASTER_DISTRIBUTOR') {
      this.logout();
      return false;
    }
    if (!sessionStorage.getItem('CORE_SESSION')) {
      this.logout();
      return false;
    }
    if (sessionStorage.getItem('access_token')) {
      const decToken: {exp: number, created: number} = jwt_decode(sessionStorage.getItem('access_token'));
      const startDate = new Date();
      const expDate = new Date(decToken.exp * 1000);
      const session = Math.ceil((<any>expDate - <any>startDate));

      if (expDate <= startDate) {
        this.logout();
        return false;
      }
      if (this.logOutTimer) {
        clearTimeout(this.logOutTimer);
        return true;
      }
      this.logOutTimer = setTimeout(() => {
        this.logout();
        return false;
      }, session);
    }
    return true;
  }

  logout() {
    localStorage.removeItem('user_type');
    localStorage.removeItem('user_name');
    localStorage.removeItem('brand_name');
    localStorage.removeItem('admin_name');
    localStorage.removeItem('shop_name');
    localStorage.removeItem('columnCount');
    localStorage.removeItem('u_det');
    // localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/']);
  }

  }


