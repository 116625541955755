import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AgentOnboardingDashboardComponent } from './agent-onboarding-dashboard/agent-onboarding-dashboard.component';
import { PreOnboardingDetailsComponent } from './pre-onboarding-details/pre-onboarding-details.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { AgentOnboardingDashComponent } from './dashboard/agent-onboarding-dash/agent-onboarding-dash.component';
// import { EmptyRouteComponent } from './empty-route/empty-route.component';
// import { FooterComponent } from './footer/footer.component';

const routes: Routes = [
  {
    path: 'distributor',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'common_onboarding',
        loadChildren: () => import('./common-onboarding/common-onboarding.module').then(m=>m.CommonOnboardingModule)
      },
      {
        path: 'member',
        canActivate: [AuthGuard],
        loadChildren: () => import('./member/member.module').then(module => module.MemberModule)
      },
      {
        path: 'services',
        canActivate: [AuthGuard],
        loadChildren: () => import('./services/services.module').then(module => module.ServicesModule)
      },
      {
        path: 'reports',
        canActivate: [AuthGuard],
        loadChildren: () => import('./reports/reports.module').then(module => module.ReportsModule)
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      // {
      //   path: 'dashboard/aeps-dashboard',
      //   component: AepsDasComponent
      // },
      {
        path: 'dashboard/:id',
        component: AgentOnboardingDashComponent
      },
      // {
      //   path: 'dashboard/twowheeler-dashboard',
      //   component: TwoWheelerDashComponent
      // },
      {
        path: "agent-onboarding-dashboard",
        component: AgentOnboardingDashboardComponent
      },
      {
        path: "pre-onboarding-details",
        component: PreOnboardingDetailsComponent
      }

    ]
  },
  { path: '**', component: EmptyRouteComponent }
];
//console.log(routes);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
