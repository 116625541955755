<div id="http-loader" *ngIf="isSpinnerVisible">
    <!-- original spiinner starts -->
    <!-- <div class="loader-bg">
        <div class="loader-spinner-container" *ngIf="spinner === Spinkit.skLine">
            <div class="test-spinner" [class.colored]="!backgroundColor">
                <div class="bounce1" [style.background-color]='backgroundColor'></div>
                <div class="bounce2" [style.background-color]='backgroundColor'></div>
                <div class="bounce3" [style.background-color]='backgroundColor'></div>
            </div>
            
        </div>
    </div>  -->
    <!-- original spiinner ends -->


    <!-- custome spinner starts -->
    <div class="text-center-spinner">
        <div class="loader3">
            <span></span>
            <span></span>
        </div>
    </div>
    <p class="text-center-spinner">Loading...</p>
    <!-- custome spinner ends -->
</div>