import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { embedDashboard } from 'superset-ui-sdk/embedded-sdk';


@Component({
  selector: 'app-agent-onboarding-dashboard',
  templateUrl: './agent-onboarding-dashboard.component.html',
  styleUrls: ['./agent-onboarding-dashboard.component.scss']
})
export class AgentOnboardingDashboardComponent implements OnInit {
  token: any;
  embedded_id: any;
  // superset data
  url: any = environment.superSetUrl;
  embedded_ids_array: any = [];
  superset_password: any;
  embedded_key_array: any = [];
  loadResponse: boolean = false;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.fetchGuestTokenFromBackend()

  }


  async fetchGuestTokenFromBackend() {
    const data = environment.supersetReqBody
    let header = new HttpHeaders().set("Access-Control-Allow-Origin", "*");
    this.http.post(`${this.url}/api/v1/security/login`, data, { headers: header }).subscribe(
      async (res: any) => {
        //console.log(res);
        this.getGuestToken(res.access_token);
      },
      err => {
        //console.log(err.error);
      }
    )
  }
  getGuestToken(token: string) {
    const id = environment.supersetId;
    let reqBody = {
      "resources": [
        {
          "id": id,
          "type": "dashboard"
        }
      ],
      // "rls": [{ "clause": "username='iserveutech'" }],
      "rls": [],
      "user": {
        "first_name": "Iserveu",
        "last_name": "Superset",
        "username": "admin"
      }
    };
    let header = new HttpHeaders({
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    });
    this.http.post(`${this.url}/api/v1/security/guest_token/`, reqBody, { headers: header }).subscribe(
      res => {
        this.token = JSON.parse(JSON.stringify(res)).token;
        embedDashboard({
          id: environment.supersetId,
          supersetDomain: `${this.url}`,
          mountPoint: document.getElementById("cbc-dashboard"),
          fetchGuestToken: async () => this.token,
          dashboardUiConfig: {
            hideTitle: false, hideTab: false,
          },
          debug: true,
        });

        this.loadResponse = true;
      },
      err => {
        //console.log(err.error);
      }
    )
  }
}


