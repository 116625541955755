import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpClient
} from '@angular/common/http';
import { Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  logOutTimer: any;
  allowRefresh = true;
  constructor(private router: Router, private http: HttpClient) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let access_token = sessionStorage.getItem('access_token');
    if (sessionStorage.getItem('access_token')) {
      const decToken: { exp: number, created: number } = jwt_decode(sessionStorage.getItem('access_token'));
      const startDate = new Date();
      const expDate = new Date(decToken.exp * 1000);
      const session = Math.ceil((<any>expDate - <any>startDate));
      const mins = Math.floor((session / 1000) / 60);
      sessionStorage.setItem('min', JSON.stringify(mins));

      if (expDate <= startDate) { this.logout(); }
      if (this.allowRefresh && (mins <= 5)) {
        //console.log("refresh token");

        this.refreshToken();
      }
      if (this.logOutTimer) {
        clearTimeout(this.logOutTimer);
      }
      this.logOutTimer = setTimeout(() => {
        window.removeEventListener('offline', this.handleOffline, true);
        this.logout();
      }, session);

    } else {
      this.logout();
    }
    if (req.url.includes('oauth/token')) {
      req = req.clone({
        setHeaders: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Basic ` + btoa('isu-kotak-client:isu-kotak-password')
        }
      });
    } else if (req.url.startsWith(environment.superSetUrl)) {
      // do not pass header
    }
    else if (req.url.endsWith('isu_elastic_user/_search') || req.url.endsWith('user_elastic/_search') || req.url.endsWith('user_long_elastic/_search') || req.url.endsWith('kotak_elastic/_search')) {
      req = req.clone({
        setHeaders: {
          Authorization: `Basic ZWxhc3RpYzpUQWhJamJ4U2RzRzRRRDY3WWVmZTZQdzg=`
        }
      });
    } else {
      req = req.clone({
        setHeaders: {
          Authorization: access_token
        }
      });
    }
    return next.handle(req);
  }
  handleOffline = (evt) => {
    // //console.log('Internet Disconnected.')
    // //console.log('Internet Disconnected Event: ', evt);
  }
  logout = () => {
    localStorage.removeItem('user_name');
    localStorage.removeItem('brand_name');
    localStorage.removeItem('admin_name');
    localStorage.removeItem('shop_name');
    localStorage.removeItem('columnCount');
    localStorage.removeItem('u_det');
    // localStorage.clear();
    sessionStorage.clear();
    this.router.navigateByUrl("/")
  }
  refreshToken() {
    this.allowRefresh = false;
    let params = new URLSearchParams();
    params.set('grant_type', 'refresh_token');
    params.set('refresh_token', sessionStorage.getItem('refresh_token'));
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ` + btoa('isu-kotak-client:isu-kotak-password')
    });
    let options = { headers: headers };
    this.http.post(`${environment.authTokenApi}/oauth/token`, params.toString(), options)
      .pipe()
      .subscribe(
        (res: any) => {
          sessionStorage.setItem('access_token', res.access_token);
          sessionStorage.setItem('refresh_token', res.refresh_token);
          this.allowRefresh = true;
        },
        (err: any) => {
          //console.log('Refresh Token Error: ', err);
        }
      )
  }
}
