import { Injectable } from "@angular/core";





@Injectable()

export class NavigationItem {
  public navitems = [
    {
      parent: false,
      displayName: 'Dashboard',
      kycStatus: true,
      iconName: 'fa fa-area-chart',
      route: 'distributor/dashboard',
      type: true
    },
    {
      parent: false,
      displayName: 'Onboarding Dashboard',
      kycStatus: true,
      iconName: 'fa fa-area-chart',
      route: 'distributor/agent-onboarding-dashboard',
      type: true
    },
    {
      parent: true,
      displayName: 'Services',
      iconName: 'fa fa-server',
      type: true,
      kycStatus: sessionStorage.getItem('kycActiveStatus') == '10' ? true : false,
      // kycStatus: false,
      children: [
        {
          parent: false,
          displayName: 'My Services',
          iconName: 'lens_blur',
          kycStatus: true,
          route: 'distributor/services/myService',
          type: true,
        },
        {
          parent: false,
          displayName: 'Update Agent Service',
          iconName: 'lens_blur',
          kycStatus: true,
          route: 'distributor/services/updateAgentService',
          type: true,
        },
        {
          parent: false,
          displayName: 'View Services',
          iconName: 'lens_blur',
          kycStatus: true,
          route: 'distributor/services/viewService',
          type: true,
        },
      ]
    },
    {
      parent: true,
      displayName: 'Agent',
      iconName: 'fa fa-users',
      type: true,
      kycStatus: sessionStorage.getItem('kycActiveStatus') == '10' ? true : false,
      children: [
        {
          parent: false,
          displayName: 'Create Agent',
          iconName: 'lens_blur',
          kycStatus: true,
          route: 'distributor/member/createuser',
          type: true,
        },
        {
          parent: false,
          displayName: 'Pre-Onboarding',
          iconName: 'lens_blur',
          kycStatus: true,
          route: 'distributor/pre-onboarding-details',
          type: true,
        }
      ]
    },
    {
      parent: false,
      displayName: 'Agent Onboarding',
      iconName: 'fa fa-user',
      kycStatus: sessionStorage.getItem('kycActiveStatus') == '10' ? true : false,
      route: 'distributor/common_onboarding/agent',
      type: true,
    },
    {
      parent: false,
      displayName: 'Self Onboarding',
      iconName: 'fa fa-user-plus',
      kycStatus: true,
      route: 'distributor/common_onboarding/cbc_kyc_journey',
      type: true,
    },
    {
      parent: true,
      displayName: 'Reports',
      iconName: 'fa fa-file-excel-o',
      type: true,
      kycStatus: true,
      // kycStatus: false,
      children: [
        {
          parent: false,
          displayName: 'Transacation Report',
          iconName: 'fa fa-table',
          kycStatus: true,
          route: 'distributor/reports/transactionReport',
          type: true,
        },
        {
          parent: false,
          displayName: 'Wallet Report',
          iconName: 'lens_blur',
          kycStatus: true,
          route: 'distributor/reports/walletReport',
          type: true,
        },
        {
          parent: false,
          displayName: 'Two Wheeler Report',
          iconName: 'lens_blur',
          kycStatus: true,
          route: 'distributor/reports/twoWhellerReport',
          type: true,
        },
        {
          parent: false,
          displayName: 'CV/CE Report',
          iconName: 'lens_blur',
          kycStatus: true,
          route: 'distributor/reports/cvceReport',
          type: true,
        },
      ]
    }
  ];
}
